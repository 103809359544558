import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Circles } from 'react-loader-spinner'
import { retry } from '../utils/PromiseRetry';


const Careers = React.lazy(() => retry(() => import('./components/web/views/OurFamily/Careers')));
const CareerDetails = React.lazy(() => retry(() => import('./components/web/views/OurFamily/Careers/CareerDetails')));
const ThankYouPage = React.lazy(() => retry(() => import('./components/web/views/ThankYouPage')));
const NotFound = React.lazy(() => retry(() => import('./components/commons/404')));
const JobApplicationsSubmitted = React.lazy(() => retry(() => import('./components/web/views/JobApplicationsSubmitted')));

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    children: [
      { path: "", element: <Careers /> },
      { path: ":slug", element: <CareerDetails /> },
      { path: "thank-you", element: <ThankYouPage /> },
      { path: "application-submitted", element: <JobApplicationsSubmitted /> }
    ],
  },
]);

function App() {
  return (
    <Suspense fallback={
      <span style={{ textAlign: "center", display: "block" }}>
        <div
          style={{
            display: "inline-block",
            marginTop: "300px",
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={false}
            timeout={8000}
          />
        </div>
      </span>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
