const API_URL = process.env.REACT_APP_backendServiceURL; 

const Apis = {
    BusinessForm: `${API_URL}/api/business/business-requests`,
    CheckEmailPhoneNoBusiness: (data) => `${API_URL}/api/business/check-email-phone?email=${data.email}&contactNumber=${data.contactNumber}`,
    CountryData: `${API_URL}/api/location/country`,
    StateData: `${API_URL}/api/location/state`,
    CityData: `${API_URL}/api/location/city`,
    UploadAttachmentData: `${API_URL}/api/file/upload`,
    UploadAttachmentDataPdf: `${API_URL}/api/file/upload-pdf`,
    TeamListData: `${API_URL}/api/wse/team/list/getEnableTeamMember`,
    TeamListDataByPosition: (positionId) => `${API_URL}/api/wse/team/getList/${positionId}`,
    GetAllPositionData: `${API_URL}/api/wse/enabledPositions`,
    GetPersonaData: `${API_URL}/api/persona/personas/list/enablePersona`,
    GetInterestData: `${API_URL}/api/persona/interests/list/enableInterest`,
    GetTriviaData: `${API_URL}/api/trivia/enable/list`,
    GetTriviaDataBySlug: (slug) => `${API_URL}/api/trivia/getData?slug=${slug}`,
    GetTriviaDataByCategory: (categoryId) => `${API_URL}/api/trivia/getByCategory/${categoryId}`,
    GetRecentTrivias: (count, triviaId) => `${API_URL}/api/trivia/recent/${count}/${triviaId}`,
    GetRecentHeaderTrivias: (count) => `${API_URL}/api/trivia/recent/${count}`,
    GetRelatedTrivias: (categoryId, count) => `${API_URL}/api/trivia/relatedByCategory/${categoryId}/${count}`,
    GetInfrograpicsData: `${API_URL}/api/infrograpic/enable/list`,
    GetInfrograpicsDataBySlug: (slug) => `${API_URL}/api/infrograpic/getData?slug=${slug}`,
    GetInfrograpicDataByCategory: (categoryId) => `${API_URL}/api/infrograpic/getByCategory/${categoryId}`,
    GetRecentInfrograpics: (count, infoId) => `${API_URL}/api/infrograpic/recent/${count}/${infoId}`,
    GetRecentHeaderInfrograpics: (count) => `${API_URL}/api/infrograpic/recent/${count}`,
    GetRelatedInfrograpics: (categoryId, count) => `${API_URL}/api/infrograpic/relatedByCategory/${categoryId}/${count}`,
    GetCategories: `${API_URL}/api/categories/get-categories`,
    GetBlogDetailsBySlug: (slug) => `${API_URL}/api/blog/blogdetails/${slug}`,
    GetBlogMetaBySlug: (slug) => `${API_URL}/api/blog/metaKeys/${slug}`,
    GetAllBlogs: `${API_URL}/api/blog/enable/list`,
    GetRecentBlogs: (count, blogId) => `${API_URL}/api/blog/recent/${count}/${blogId}`,
    GetReactHeaderBlogs: (count) => `${API_URL}/api/blog/recent/${count}`,
    GetBlogDataByCategory: (categoryId) => `${API_URL}/api/blog/categories/get/${categoryId}`,
    GetRecentData: (count) => `${API_URL}/api/trivia/get-recent-data?limit=${count}`,
    GetCareersDepartment: (jobType) => `${API_URL}/api/career/departments/enable-list`,
    GetCareersPositionDataBySlug: (slug) => `${API_URL}/api/career/job-description/getDataBySlug?slug=${slug}`,
    GetCareersRelatedPositions: (data) => `${API_URL}/api/career/job-description/getTopPosition?id=${data?.id}&jobType=${data?.jobType}&size=${data?.size}`,
    GetPositionByDepartment: (data) => `${API_URL}/api/career/job-description/getByDepartment?departmentId=${data?.departmentId}&jobType=${data?.jobType}&page=${data?.page}&size=${data?.size}`,
    RequestApplicant: `${API_URL}/api/career/applicant/create`,
}

export {API_URL, Apis}