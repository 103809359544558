import { createAsyncThunk } from "@reduxjs/toolkit";
import { createBusinessRequest } from "../../app/components/services/GetBusinessApi";

export const submitContactUsData = createAsyncThunk('contact/add', async(postDataObject, { rejectWithValue }) => {
    try {
        const postData = postDataObject;
        const { data } = await createBusinessRequest(postData);
        return postData;
      } catch (error) {
        return rejectWithValue(error.message);
      }
})