import axios from "axios"
import { Apis } from "../../../../config"

export const createBusinessRequest = async (data) => {
    try {
        const business = await axios.post(Apis.BusinessForm, data);
        if (business.data.error) {
            return business.data;
        }
        return business.data;
    } catch (error) {
        console.log(error)
    }
}

export const checkEmailPhoneNumber = async (data) => {
    try {
        const business = await axios.get(Apis.CheckEmailPhoneNoBusiness(data));
        if (business.data.error) {
            return business.data;
        }
        return business.data;
    } catch (error) {
        console.log(error)
    }
}