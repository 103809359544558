import { createSlice } from "@reduxjs/toolkit";
import { submitContactUsData } from "../actions";

const initialState = {
    userData: {},
    isLoading: false,
    isSuccess: false,
    errorMessage: ""
}

export const contactUsSlice = createSlice({
    name: "ContactUs",
    initialState: initialState,
    extraReducers(builder) {
        builder.addCase(submitContactUsData.pending, (state, action) => {
            state.isLoading = true;
        }),
        builder.addCase(submitContactUsData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userData = action.payload;
        }),
        builder.addCase(submitContactUsData.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.userData = action.payload;
            state.errorMessage = action.payload;
        })
    }
})

export default contactUsSlice.reducer;